import React from 'react';

import ArrowMiniIcon from '../../../../images/ArrowMini';
// import WillIcon from '../../../../images/Will';
import { Typed } from '../../../Typed';
import { list } from '../A/Hero';

import style from './Hero.scss';

import cn from 'classnames';

const words = ['Your Money,', 'Your Time,', 'Your Credit,', 'Your Future,', 'Your Life,'];

const HeroB = () => {
  return (
    <section className={style.hero}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 col-lg-5">
            <h1>
              {words && (
                <Typed
                  strings={words}
                  typeSpeed={70}
                  backSpeed={70}
                  backDelay={1000}
                  smartBackspace={false}
                  loop
                  cursorChar=""
                />
              )}
              <br />
              Your Way
              <small>
                Financial Independence Made Simple: <br className="d-none d-lg-block" />
                Earn More, Save More, Live Better.
              </small>
            </h1>
          </div>
          <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
            <div className={style.items}>
              {list.slice(0, 6).map((item) => {
                return (
                  <a
                    key={item.href + item.title}
                    href={item.href}
                    target={item.target}
                    className={style.item}
                  >
                    {item.icon}
                    <h4>{item.title}</h4>
                  </a>
                );
              })}
            </div>
            <div className={style.lastRow}>
              {/* <a
                key={list[5].href + list[6].title}
                href={list[5].href}
                target={list[5].target}
                className={style.item}
              >
                <WillIcon />
                <h4>{list[5].title}</h4>
              </a> */}
              <a
                key={list[6].href + list[6].title}
                href={list[6].href}
                target={list[6].target}
                className={cn(style.courses, style.item)}
              >
                <h4>{list[6].title}</h4>
                <ArrowMiniIcon rotate={-90} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroB;
